import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function Toast({ open, onClose, message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
      message={message}
      autoHideDuration={4000}
    />
  );
}
