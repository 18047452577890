/**
 * Shared styles for the add/edit/read location info forms.
 */

const HORIZONTAL_MARGIN = '1.5rem';


const classes = {
  header: "d-flex align-items-center justify-content-center my-3",
  infoRow: (idx) => `${idx ? 'mt-4' : ''}`,
  infoColumn: 'w-50 pr-4', // add the right margin to reduce crowdiness
  completionRow: 'mt-5 d-flex justify-content-between'
}

const styles = {
  container: {
    margin: `0 ${HORIZONTAL_MARGIN}`
  },

  headerText: {
    color: '#333',
    fontSize: '1.25rem',
    fontWeight: '500',
  }, 
  headerExitIcon: {
    position: 'absolute',
    left: HORIZONTAL_MARGIN
  },

  infoRow: {
    display: 'grid',
    gridTemplateRows: '0fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0 10px',
    gridAutoFlow: 'column'
  },
  label: {
    fontSize: '.8rem',
    color: 'rgba(13, 19, 26, .6)',
    textTransform: 'uppercase'
  },
  inputContainer: {
    backgroundColor: "#EEE",
    borderRadius: 5
  },
  input: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 0,
    padding: 5
  }
}

export default { classes, styles };
