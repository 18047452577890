import React, { Component } from "react";
import { formatting } from "./util";
import { Home, HeadcountLobby, Headcount, LoginLink } from "./screens";
import CompletedCount from "./screens/headcount/CompletedCount";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import queryString from "query-string";
import { auth, api } from "./util";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

export default class App extends Component {
  state = {
    isLoggedIn: false,
  };

  componentDidMount() {
    api.isLoggedIn().then((isLoggedIn) => this.setState({ isLoggedIn }));
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <Route
              exact
              path="/"
              render={() => (this.state.isLoggedIn ? <Home /> : <LoginLink />)}
            />

            <Route exact path="/login" render={() => <LoginLink />} />

            <Route
              exact
              path="/authorized"
              render={() => {
                auth.setToken();
                return <Redirect to="/" />;
              }}
            />

            <Route
              exact
              path="/customer/:customerPath/verify"
              render={({ match }) => {
                if (!this.state.isLoggedIn) {
                  return <LoginLink />;
                }

                const { customerPath } = match.params;

                const { customerId, customerName } = formatting.getPathParams(
                  customerPath,
                  "customer",
                );

                return (
                  <HeadcountLobby
                    customerId={customerId}
                    customerName={customerName}
                  />
                );
              }}
            />

            <Route
              exact
              path="/customer/:customerPath/verify/count"
              render={({ match }) => {
                if (!this.state.isLoggedIn) {
                  return <LoginLink />;
                }

                const { customerPath } = match.params;

                const { customerId, customerName } = formatting.getPathParams(
                  customerPath,
                  "customer",
                );

                if (window.location.search) {
                  const { headcountId } = queryString.parse(
                    window.location.search,
                  );

                  if (!headcountId) {
                    alert("Invalid headcount URL to join with.");
                    return <div>Invalid URL</div>;
                  }

                  return (
                    <Headcount
                      customerId={customerId}
                      customerName={customerName}
                      headcountId={parseInt(headcountId)}
                    />
                  );
                }

                return (
                  <Headcount
                    customerId={customerId}
                    customerName={customerName}
                  />
                );
              }}
            />

            <Route
              exact
              path="/customer/:customerPath/verify/completed/:headcountId"
              render={({ match }) => {
                if (!this.state.isLoggedIn) {
                  return <LoginLink />;
                }

                const { customerPath } = match.params;

                const { customerId, customerName } = formatting.getPathParams(
                  customerPath,
                  "customer",
                );

                return (
                  <CompletedCount
                    customerId={customerId}
                    customerName={customerName}
                  />
                );
              }}
            />
          </Router>
        </CssBaseline>
      </ThemeProvider>
    );
  }
}
