import React, { useMemo } from "react";
import { Selector, SelectorFormatters } from "../../components";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Header from "../../components/Header";
import { constants } from "../../util";

const useStyles = makeStyles(() => ({
  backToLobbyButton: {
    color: "white",
    textTransform: "none",
  },
  rootStartCountButton: {
    backgroundColor: constants.COLORS.purple,
    color: "white",
    "&$disabled": {
      color: "white !important",
      opacity: 0.3,
    },
    "&:hover": {
      backgroundColor: constants.COLORS.purpleDark,
    },
  },
  disabledStartCountButton: {
    color: "white !important",
    opacity: 0.3,
    "&$root": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: constants.COLORS.purple,
    },
  },
  rootEndCountButton: {
    backgroundColor: "black",
    color: "white",
    "&$disabled": {
      color: "white !important",
      opacity: 0.3,
    },
    "&:focus": {
      backgroundColor: constants.COLORS.greySix,
    },
    "&:hover": {
      backgroundColor: constants.COLORS.greySix,
    },
  },
  disabledEndCountButton: {
    color: "white !important",
    opacity: 0.3,
    "&$root": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  rootIncrementButton: {
    touchAction: "manipulation", // prevents double tap zoom on mobile
    backgroundColor: constants.COLORS.purpleLight,
    borderRadius: 0,
    height: "8rem",
    "& svg": {
      fontSize: "4rem",
    },
    "&:focus": {
      backgroundColor: constants.COLORS.purpleLight,
    },
  },
  iconButton: {
    color: constants.COLORS.purple,
  },
  disabledIncrementButton: {
    opacity: 0.3,
  },
  rootDecrementButton: {
    touchAction: "manipulation", // prevents double tap zoom on mobile
    backgroundColor: constants.COLORS.purpleLight,
    borderRadius: 0,
    height: "8rem",
    "& svg": {
      fontSize: "4rem",
    },
    "&:focus": {
      backgroundColor: constants.COLORS.purpleLight,
    },
  },
  disabledDecrementButton: {
    opacity: 0.3,
  },
}));

const Headcount = ({
  zones,
  setZone,
  countDelta,
  zone,
  count,
  startTime,
  initialCount,
  isInitiated,
  inputInitialCount,
  startSession,
  endSession,
  customerName,
  customerId,
  history,
}) => {
  const classes = useStyles();

  const isValidStartState = useMemo(() => {
    const validInitialCount =
      Number.isInteger(initialCount) && initialCount > 1;
    return validInitialCount && Boolean(zone);
  }, [initialCount, zone]);

  const handleBackToLobbyClick = () => {
    history.push(`/customer/${customerName}-${customerId}/verify`);
  };

  const dropdownItems = (() => {
    if (zones.length) {
      return zones.map(SelectorFormatters.toSelector.zone);
    }

    if (zone) {
      return [{ label: zone.zoneName, value: zone.zoneId }];
    }

    return [];
  })();

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      boxShadow: state.isFocused ? constants.COLORS.purple : "lightgrey",
      borderColor: state.isFocused ? constants.COLORS.purple : "lightgrey",
      "&:active": {
        boxShadow: constants.COLORS.purple,
        borderColor: constants.COLORS.purple,
      },
      "&:hover": {
        boxShadow: constants.COLORS.purple,
        borderColor: constants.COLORS.purple,
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor:
          state.isSelected || state.isFocused
            ? constants.COLORS.purpleLight
            : "inherit",
        color: "black",
        "&:active": {
          backgroundColor: constants.COLORS.purpleLight,
        },
      };
    },
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Header
        title={customerName}
        action={
          <Button
            className={classes.backToLobbyButton}
            startIcon={<ArrowBackIcon htmlColor="white" />}
            onClick={handleBackToLobbyClick}
          >
            Lobby
          </Button>
        }
      />
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          flexGrow: 1,
          minHeight: 0,
          flexDirection: "column",
        }}
      >
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box height={410}>
            <Box mt={2}>
              <Typography align="center">
                <strong>Counting Location</strong>
              </Typography>
              <Selector
                items={dropdownItems}
                select={setZone}
                selectedValue={zone ? zone.zoneId : null}
                isDisabled={zones.length === 0}
                styles={customSelectStyles}
              />
            </Box>
            <Box mt={4}>
              <Typography align="center">
                <strong>Starting Count</strong>
              </Typography>
              <TextField
                type="number"
                value={initialCount}
                onChange={inputInitialCount}
                disabled={isInitiated}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mt={5} display="flex" justifyContent="center">
              {!isInitiated && (
                <Button
                  onClick={startSession}
                  disabled={!isValidStartState}
                  classes={{
                    disabled: classes.disabledStartCountButton,
                    root: classes.rootStartCountButton,
                  }}
                >
                  Start Count Session
                </Button>
              )}
              {isInitiated && (
                <Button
                  onClick={endSession}
                  classes={{
                    disabled: classes.disabledEndCountButton,
                    root: classes.rootEndCountButton,
                  }}
                >
                  End Count Session
                </Button>
              )}
            </Box>
            {isInitiated && (
              <Box
                display="flex"
                mt={2}
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Typography align="center">
                  <strong>Delta</strong>
                </Typography>
                <Typography variant="h1" style={{ fontSize: "8rem" }}>
                  {countDelta}
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            {isInitiated && (
              <Typography gutterBottom>Start time: {startTime}</Typography>
            )}
            {!isInitiated && (
              <Typography gutterBottom>start session to count:</Typography>
            )}
            <Box display="flex" gridGap={8}>
              <Box flex={1} marginLeft={-4}>
                <Button
                  disabled={!isInitiated}
                  fullWidth
                  onClick={() => {
                    count(-1);
                    if (window.navigator && window.navigator.vibrate) {
                      window.navigator.vibrate(50);
                    }
                  }}
                  classes={{
                    root: classes.rootDecrementButton,
                    disabled: classes.disabledDecrementButton,
                  }}
                >
                  <RemoveIcon
                    size="large"
                    classes={{ root: classes.iconButton }}
                  />
                </Button>
              </Box>

              <Box flex={1} marginRight={-4}>
                <Button
                  disabled={!isInitiated}
                  fullWidth
                  className={classes.incrementButton}
                  onClick={() => {
                    count(1);
                    if (window.navigator && window.navigator.vibrate) {
                      window.navigator.vibrate(50);
                    }
                  }}
                  classes={{
                    root: classes.rootIncrementButton,
                    disabled: classes.disabledIncrementButton,
                  }}
                >
                  <AddIcon
                    size="large"
                    classes={{ root: classes.iconButton }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withRouter(Headcount);
