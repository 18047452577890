/**
 * URL formatting and extraction functions.
 */

/**
 * Functions to convert names and ID's in and out of the URL
 * path ID Format
 */
const formatName = (name) => `${name.split(' ').join('_')}`;
const getName = (formattedName) => `${formattedName.split('_').join(' ')}`;
const formatPathId = (name, id) => `${formatName(name)}-${id}`;


/**
 * Construct the portion of the url based on the type of url you are appending.
 * @param {Str} name - Name of the location
 * @param {Num} id   - ID of the location
 * @param {Str} type - Type of location: customer | zone | subzone
 * @return {Str}
 * Formatted path variable that will be used as a portion of the URL.
 */
export const formatPath = (name, id, type) => `${type}/${formatPathId(name, id)}`;

/**
 * Extract Name and ID parameters from the path.
 * @param {Str} pathId - {formattedName}-{id}
 * @param {Str} type   - Type of location: customer | zone | subzone
 * @return {Obj}
 * Mapping of the name and ID for the given type.
 */
export function getPathParams(pathId, type) {
  const [formattedName, id] = pathId.split('-');
  return {
    [`${type}Name`]: getName(formattedName),
    [`${type}Id`]: parseInt(id)
  }
};
