import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { api } from "../../util";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/Header";

const HomeContainer = ({ history }) => {
  const [state, setState] = useState({
    customers: [],
  });

  useEffect(() => {
    api.request("GET", "install/customers").then(({ customers }) => {
      // sort customers by name
      customers.sort((a, b) => {
        const nameA = a.customerName.toLowerCase();
        const nameB = b.customerName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      setState((prevState) => ({
        ...prevState,
        customers,
      }));
    });
  }, []);

  const selectCustomer = (id, name) => {
    history.push(`/customer/${name}-${id}/verify`);
  };

  return <Home customers={state.customers} selectCustomer={selectCustomer} />;
};

export default withRouter(HomeContainer);

//const customerIdsToDisplay = new Set([21, 76]);
const Home = ({ customers, selectCustomer }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCustomers = customers.filter((c) => {
    const customerName = c.customerName.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return customerName.includes(searchTermLower);
  });

  return (
    <div style={styles.bodyBG}>
      <Header title="Customers" />
      <Container>
        <Box my={4} display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchTerm}
            fullWidth
          />
        </Box>
        <Grid container spacing={4} style={styles.cardGrid}>
          {filteredCustomers.map((c, idx) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={idx}>
                <Card
                  onClick={() => {
                    selectCustomer(c.customerId, c.customerName);
                  }}
                  style={styles.card}
                  elevation={0}
                >
                  <Typography variant="h6">{c.customerName}</Typography>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

const styles = {
  bodyBG: {
    height: "100%",
  },

  header: {
    height: "2.5rem",
    backgroundColor: "#00061A",
    width: "100vw",
  },

  headerText: {
    color: "white",
    fontSize: "1.25rem",
    fontWeight: "bold",
  },

  locationGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "1rem",
  },

  cardGrid: {
    paddingBottom: 24,
  },

  card: {
    padding: 16,
    cursor: "pointer",
    height: "100%",
  },
};
