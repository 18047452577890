/**
 * Wrapper around react-select to work with the data coming in.
 * Original version used standard select + option tags, so the wrapper transforms
 * the prop vals into something this component can work with.
 *
 * We'll manage our own state, and fireback the data whenever the value changes
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

export default class SelectorContainer extends Component {
  constructor(props) {
    super(props);

    // convert the items array into Selector Objects
    const { items, selectedValue } = props;

    // find the initial selected
    let selected = null;
    if ("defaultIdx" in this.props) {
      selected = items[props.defaultIdx];
    }
    if (selectedValue) {
      const foundItem = items.find(({ value }) => value === selectedValue);
      if (foundItem) {
        selected = foundItem;
      }
    }

    this.state = {
      items,
      selected,
    };
  }
  /**
   * Need the ability to filter the current options from external component.
   *
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let newItems = nextProps.items;

    let isSame = true;
    if (newItems.length !== prevState.items.length) isSame = false;
    else {
      newItems.forEach((i, idx) => {
        if (i.id !== prevState.items[idx].id) {
          isSame = false;
        }
      });
    }

    // update if necessary
    if (!isSame) {
      return { items: newItems };
    }

    // no update necessary
    return null;
  }

  onChange = (selected) => {
    this.setState({ selected }, () => {
      this.props.select(selected);
    });
  };

  render() {
    const { selected, items } = this.state;

    return (
      <Select
        isClearable
        value={selected}
        onChange={this.onChange}
        options={items}
        {...this.props}
      />
    );
  }
}

SelectorContainer.propTypes = {
  select: PropTypes.func.isRequired,
  items: PropTypes.arrayOf((propValue, key) => {
    if (!("label" in propValue[key]) || !("value" in propValue[key])) {
      return new Error(
        `Invalid value in options prop, need to have "value" and "name" in the args.`
      );
    }
  }),
  defaultValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
};
