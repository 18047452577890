/**
 * PropType custom validator functions for more complex prop-checking.
 */

export const multiSelectObj = (propValue, key) => {
  if (!('label' in propValue[key]) || !('value' in propValue[key])) {
    return new Error(`Invalid multi-selector object in prop. Value passed was: ${JSON.stringify(propValue[key])}`)
  }
}

export const zone = (propValue, key) => {
  if (!(
    ('zoneId' in propValue[key])     &&
    ('zoneName' in propValue[key])   &&
    ('buildingId') in propValue[key] &&
    ('adjacent') in propValue[key]
  )) {
    return new Error(`Invalid ZoneForm object: ${JSON.stringify(propValue[key])}`);
  }
}

export const adjacentZone = (propValue, key) => {
  if (!('zoneId' in propValue[key]) || !('zoneName' in propValue[key])) {
    return new Error(`Invalid Adjacent Zone Form Object`);
  }
}
