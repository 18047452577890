import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { api, constants } from "../../util";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Header from "../../components/Header";
import Toast from "../../components/Toast";
import DeleteHeadcount from "../../components/DeleteHeadcount";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LabelAndValue from "../../components/LabelAndValue";

const useStyles = makeStyles(() => ({
  startingCountInput: {
    backgroundColor: "white",
  },
  backToLobbyButton: {
    color: "white",
    textTransform: "none",
  },
}));

const CompletedCount = ({ customerName, customerId, history }) => {
  const {
    headcountId,
    buildingName,
    // buildingId,
    zoneName,
    // zoneId,
    start,
    end,
    totalCount,
    notes,
  } = history.location.state;

  const twentyFourHoursAgoInMs = moment().subtract(24, "hours").valueOf();
  const showDelete = end * 1000 > twentyFourHoursAgoInMs;

  const classes = useStyles();
  const [state, setState] = useState({
    note: notes || "",
    submittedNote: notes || "",
    isLoading: false,
    noteErrorMessage: "",
    deleteErrorMessage: "",
    successToastOpen: false,
    deleteToastOpen: false,
    isDeleteHeadcountOpen: false,
    isEditingNote: notes && notes.length >= 1 ? false : true,
  });
  const {
    note,
    isLoading,
    noteErrorMessage,
    deleteErrorMessage,
    submittedNote,
    successToastOpen,
    deleteToastOpen,
    isDeleteHeadcountOpen,
    isEditingNote,
  } = state;

  const isSubmitDisabled =
    note.length === 0 || isLoading || note === submittedNote;

  const handleDeleteHeadcountOpen = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteHeadcountOpen: true,
    }));
  };

  const handleDeleteHeadcountClose = () => {
    setState((prevState) => ({
      ...prevState,
      isDeleteHeadcountOpen: false,
      deleteErrorMessage: "",
    }));
  };

  const handleIsEditingNote = () => {
    setState((prevState) => ({
      ...prevState,
      isEditingNote: true,
    }));
  };

  const handleCancelEditNote = () => {
    setState((prevState) => ({
      ...prevState,
      isEditingNote: false,
      note: prevState.submittedNote,
    }));
  };

  const handleInputChange = (event) => {
    const note = event.target.value;
    setState((prevState) => ({ ...prevState, note }));
  };

  const handleInputFocus = () => {
    setState((prevState) => ({ ...prevState, noteErrorMessage: "" }));
  };

  const handleBackToLobbyClick = () => {
    history.push(`/customer/${customerName}-${customerId}/verify`);
  };

  const handleSuccessToastClose = () => {
    setState((prevState) => ({ ...prevState, successToastOpen: false }));
  };

  const handleDeleteToastClose = () => {
    setState((prevState) => ({ ...prevState, deleteToastOpen: false }));
  };

  const handleSubmitNoteClick = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        successToastOpen: false,
      }));
      await api.updateHeadcountNote({
        headcountId,
        note,
      });
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        submittedNote: prevState.note,
        successToastOpen: true,
        isEditingNote: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        noteErrorMessage: "Error submitting note",
      }));
    }
  };

  const handleDeleteHeadcount = async () => {
    if (!headcountId) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        deleteToastOpen: false,
        deleteErrorMessage: false,
      }));
      await api.deleteHeadcount(headcountId);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isDeleteHeadcountOpen: false,
        deleteToastOpen: true,
      }));
      history.push({
        pathname: `/customer/${customerName}-${customerId}/verify`,
        state: {
          lastDeletedInMs: moment().valueOf(),
        },
      });
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        deleteErrorMessage: "Error deleting headcount",
      }));
    }
  };

  if (!headcountId) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={500}
      >
        <Typography>Error finding headcount. Please return to lobby</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Header
        title={customerName}
        action={
          <Button
            className={classes.backToLobbyButton}
            startIcon={<ArrowBackIcon htmlColor="white" />}
            onClick={handleBackToLobbyClick}
          >
            Lobby
          </Button>
        }
      />
      <Container>
        <Box mt={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={showDelete ? 0 : 2}
          >
            <Typography variant="h5">Completed Count Details</Typography>
            {showDelete && (
              <IconButton
                style={{ marginRight: -12 }}
                onClick={handleDeleteHeadcountOpen}
              >
                <DeleteOutlineOutlinedIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
          <Card elevation={0} style={{ boxShadow: "none" }}>
            <Box
              p={4}
              display="flex"
              flexDirection="column"
              flex={1}
              style={{ gap: 16 }}
            >
              <LabelAndValue label="Zone" value={zoneName} />
              <LabelAndValue label="Building" value={buildingName} />
              <LabelAndValue label="Headcount id" value={headcountId} />
              <LabelAndValue label="Customer id" value={customerId} />
              <LabelAndValue
                label="Start"
                value={moment(start * 1000).format("MM/DD/YYYY, hh:mm a")}
              />
              <LabelAndValue
                label="End"
                value={moment(end * 1000).format("MM/DD/YYYY, hh:mm a")}
              />
              <LabelAndValue label="Total Count" value={totalCount} />
            </Box>
          </Card>
        </Box>
        <Box my={2}>
          <Typography variant="h5">Notes</Typography>
        </Box>
        {!isEditingNote && (
          <>
            <Card elevation={0} style={{ boxShadow: "none", minHeight: 155 }}>
              <Box p={4}>
                <Typography variant="body2">{note}</Typography>
              </Box>
            </Card>
            <Button
              fullWidth
              variant="contained"
              onClick={handleIsEditingNote}
              color="primary"
              style={{ marginTop: 16 }}
            >
              Edit
            </Button>
          </>
        )}
        {isEditingNote && (
          <>
            <TextField
              placeholder="Enter notes here"
              fullWidth
              multiline
              rows={5}
              onChange={handleInputChange}
              value={note}
              variant="outlined"
              disabled={isLoading}
              onFocus={handleInputFocus}
              style={{
                backgroundColor: "white",
                borderRadius: 8,
              }}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmitNoteClick}
              color="primary"
              disabled={isSubmitDisabled}
              style={{ marginTop: 16 }}
            >
              Submit
            </Button>
            {submittedNote.length >= 1 && (
              <Button
                fullWidth
                variant="contained"
                onClick={handleCancelEditNote}
                style={{ marginTop: 16 }}
              >
                Cancel
              </Button>
            )}
          </>
        )}
        {noteErrorMessage && (
          <Typography variant="body2" style={{ color: constants.COLORS.red }}>
            {noteErrorMessage}
          </Typography>
        )}
        <Toast
          open={successToastOpen}
          onClose={handleSuccessToastClose}
          message="Note successfully submitted"
        />
        <Toast
          open={deleteToastOpen}
          onClose={handleDeleteToastClose}
          message="Headcount successfully deleted"
        />
        <DeleteHeadcount
          onClose={handleDeleteHeadcountClose}
          isOpen={isDeleteHeadcountOpen}
          headcountToDelete={{
            headcountId,
            zoneName,
            start,
            end,
          }}
          onDelete={handleDeleteHeadcount}
          isLoading={isLoading}
          errorMessage={deleteErrorMessage}
        />
      </Container>
    </Box>
  );
};

CompletedCount.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default withRouter(CompletedCount);
