/**
 * Component to handle the addition and removal of adjacent zones
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { api, validators } from '../../util';
import Select from 'react-select';
import { SelectorFormatters } from '../';

export default class AdjacentZoneContainer extends Component {
  state = {
    zones: [],
    adjacentZones: [...this.props.adjacentZones].map(SelectorFormatters.toSelector.zone),
    remaining: [],
  }

  /**
   * Fetch the available adjacent zones by making a request with the 
   * filtration params.
   * 
   * @param {*} customerId 
   * @param {*} buildingId 
   * @return {Obj}
   * { zones, selected }
   */
  static getOptions(customerId, buildingId, zoneId = 0) {
    return api.getZones(customerId, buildingId)
    .then(zones => (
      zones.filter(z => z.zoneId !== zoneId).map(SelectorFormatters.toSelector.zone)
    ));
  }

  /**
   * Need to be able to find what zones are available.
   * Search by customer if no building ID is supplied
   * 
   * We don't need to manage any of the intersection between all
   * and selected - we handle that immediately by passing default values.
   */
  componentDidMount() {
    const { customerId, buildingId, zoneId } = this.props;

    AdjacentZoneContainer.getOptions(customerId, buildingId, zoneId)
    .then(zones => {
      this.setState({ zones });
    });
  }

  /**
   * Whenever an adjacent zone is changed, added or removed, we need to update
   * the parent with the updated change.
   * 
   * @param {Obj[] || Null} adjacentZones - List of selector objects
   */
  onChange = (selectedAdjacentZones) => {
    const adjacentZones = selectedAdjacentZones || [];

    this.setState({ adjacentZones }, () => {
      this.props.onChange(
        adjacentZones.map(SelectorFormatters.backToForm.zone)
      );
    });
  }

  /**
   * @TODO (?) we may want to add a componentShouldUpdate lifecycle component
   * in case a user will exteranlly add a building filter... otherwise, i can 
   * probably just disable the building filter after its been clicked or something...
   * @TODO handle the none-set case.... like default one or something
   */

  render() {
    const { zones, adjacentZones} = this.state;
    console.log(zones, adjacentZones)

    // NOTE: that marginTop: 15 on the container will fuck me eventuall
    return (
      <div>
        <Select 
          isMulti
          options={zones}
          onChange={this.onChange}
          defaultValue={[...adjacentZones]}
        />
      </div>
    )
  }
}

AdjacentZoneContainer.propTypes = {
  customerId: PropTypes.number.isRequired,     //  
  buildingId: PropTypes.number,                // optional building array to filter for
  onChange: PropTypes.func.isRequired,          // function to ensure we have a way to send the array back to caller
  adjacentZones: PropTypes.arrayOf(validators.zone),
  zoneId: PropTypes.number // optional if it's coming in as edit
}