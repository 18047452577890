/**
 * File to find a attach to a headcount, or start a new one.
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { api, constants } from "../../util";
import { FaPlus, FaSync } from "react-icons/fa";
import moment from "moment";
import queryString from "query-string";
import Header from "../../components/Header";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Toast from "../../components/Toast";
import CountCard from "../../components/CountCard";

const HeadcountLobby = ({ customerId, customerName, history }) => {
  const nowInMs = moment().valueOf();
  const showToast = history.location.state
    ? nowInMs - history.location.state.lastDeletedInMs < 4000
    : false;

  const [state, setState] = useState({
    loading: true,
    lastLoaded: null,
    headcounts: [],
    completedHeadcounts: [],
    showHeadcountDeletedToast: showToast,
  });

  const {
    headcounts,
    lastLoaded,
    loading,
    completedHeadcounts,
    showHeadcountDeletedToast,
  } = state;

  const handleDeletedToastClose = () => {
    setState((prevState) => ({
      ...prevState,
      showHeadcountDeletedToast: false,
    }));
  };

  const handleCompletedHeadcountClick = (completedHeadcount) => () => {
    history.push({
      state: { ...completedHeadcount },
      pathname: `/customer/${customerName}-${customerId}/verify/completed/${completedHeadcount.headcountId}`,
    });
  };

  const handleBackToCustomersClick = () => {
    history.push(`/`);
  };

  // Make the initial request for headcounts
  useEffect(() => {
    getData(customerId)
      .then(({ headcounts, lastLoaded, completedHeadcounts }) => {
        setState((prevState) => ({
          ...prevState,
          headcounts,
          lastLoaded,
          completedHeadcounts,
        }));
      })
      .catch((err) => {
        alert(`Failed to retrieve headcounts: ${err}`);
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }, [customerId]);

  // Fetch available headcounts to join.
  const getData = (customerId) => {
    const headcounts = api.getHeadcounts(customerId);
    const completedHeadcounts = api.getCompletedHeadcounts(customerId);
    return Promise.all([headcounts, completedHeadcounts]).then((values) => {
      return {
        headcounts: values[0],
        completedHeadcounts: values[1],
        lastLoaded: moment().format("h:mma"),
      };
    });
  };

  // Refresh the counter sessions
  const refresh = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const { headcounts, lastLoaded, completedHeadcounts } = await getData(
        customerId,
      );
      setState((prevState) => ({
        ...prevState,
        headcounts,
        lastLoaded,
        completedHeadcounts,
        loading: false,
      }));
    } catch (err) {
      alert(`Failed to retrieve headcounts: ${err}`);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  // Start a headcount
  const startHeadcount = () => {
    history.push(`/customer/${customerName}-${customerId}/verify/count`);
  };

  // Join a headcount.
  // This is effectively navigating to a headcount/count page with
  // query params to indicate with count the person is joining.
  const joinHeadcount = (headcountId) => {
    window.location = `${
      window.location.pathname
    }/count?${queryString.stringify({ headcountId })}`;
  };

  return (
    <Box>
      <Header
        title={customerName}
        action={
          <Button
            style={{ color: "white", textTransform: "none" }}
            startIcon={<ArrowBackIcon htmlColor="white" />}
            onClick={handleBackToCustomersClick}
          >
            Customers
          </Button>
        }
      />
      <Container maxWidth="sm">
        <Box style={styles.headerText} mt={4}>
          <Typography variant="h5">Ongoing Headcounts</Typography>
          <Box display="flex" alignItems="center" style={styles.headerSubtext}>
            <Box
              mr={2}
              display="flex"
              alignItems="center"
              className={`${loading ? "spin-animation" : ""}`}
            >
              <FaSync size={SUBTEXT_SIZE - 2} onClick={refresh} />
            </Box>
            Live as of {lastLoaded}
          </Box>
        </Box>
        <Box mt={3} display="flex" flexDirection="column" style={{ gap: 12 }}>
          {headcounts.length > 0 &&
            headcounts.map((h, idx) => (
              <CountCard
                key={idx}
                onClick={() => {
                  joinHeadcount(h.headcountId);
                }}
                zoneName={h.zoneName}
              />
            ))}
        </Box>
        <Box mt={3}>
          <Typography variant="h5" style={styles.headerText}>
            Completed Headcounts
          </Typography>
          {completedHeadcounts && (
            <Typography style={styles.headerSubtext}>
              {completedHeadcounts.length} recent completed headcounts
            </Typography>
          )}
          <Box
            mt={3}
            pb={2}
            display="flex"
            flexDirection="column"
            style={{ gap: 12 }}
          >
            {completedHeadcounts.length > 0 &&
              completedHeadcounts.map((completedHeadcount, idx) => (
                <CountCard
                  key={idx}
                  onClick={handleCompletedHeadcountClick(completedHeadcount)}
                  zoneName={completedHeadcount.zoneName}
                  time={completedHeadcount.end}
                />
              ))}
            {completedHeadcounts.length >=
              constants.completedHeadcountLimit && (
              <Typography
                style={{
                  ...styles.headerSubtext,
                  marginBottom: 8,
                  textAlign: "center",
                }}
              >
                Only {completedHeadcounts.length} most recent headcounts are
                shown
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ ...styles.centeredFab, ...styles.fab }}
          onClick={startHeadcount}
        >
          <FaPlus size={30} />
        </Box>
      </Container>
      <Toast
        open={showHeadcountDeletedToast}
        onClose={handleDeletedToastClose}
        message="Headcount successfully deleted"
      />
    </Box>
  );
};

const FAB_SIZE = 50;
const SUBTEXT_SIZE = 18;

const styles = {
  headerText: {
    color: constants.COLORS.greyFive,
  },
  headerSubtext: {
    fontSize: SUBTEXT_SIZE,
    color: constants.COLORS.greyFive,
  },

  fab: {
    width: FAB_SIZE,
    height: FAB_SIZE,
    borderRadius: "50%",
    backgroundColor: constants.COLORS.purple,
    color: "white",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  centeredFab: {
    position: "fixed",
    bottom: "6vh",
    right: 0,
    left: 0,
    margin: "auto",
  },
};

HeadcountLobby.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default withRouter(HeadcountLobby);
