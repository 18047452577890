import queryString from "query-string";
import { config } from ".";

const ACCESS_TOKEN = "accessToken";
export const LOGIN_URL = `https://occustall.auth.us-west-2.amazoncognito.com/oauth2/authorize?response_type=token&client_id=29c30o6f78h13bll8p2dmsa14o&redirect_uri=${config.CALLBACK_URL}`;

/**
 * Fetch & set the token in the into localstorage
 * Return true if the token was set, false otherwise.
 */
export const setToken = () => {
  if (!window.location.hash) {
    return false;
  }

  const tokens = queryString.parse(window.location.hash);
  console.log(tokens);
  if ("access_token" in tokens) {
    localStorage.setItem(ACCESS_TOKEN, tokens.access_token);
    return true;
  }

  return false;
};

/**
 * JWT from cognito or null
 */
export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

/**
 * Wrapper around the is-logged-in endpoint.. feels right to live in
 * this file anyway :)
 */
// export const isLoggedIn = () => {
//   api.isLoggedIn();
// }
