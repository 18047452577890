import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Headcount from "./Headcount";
import { api } from "../../util";
import Box from "@material-ui/core/Box";
import Header from "../../components/Header";

export default class HeadcountContainer extends Component {
  // sync the state with props
  // can't just destructure because we need to make sure the values are there
  // even if they aren't put in
  state = {
    loading: true,
    headcountId: this.props.headcountId,
    zone: this.props.zone,
    zones: [],
    initialCount: this.props.initialCount || 0,
    countDelta: 0,
    startTime: this.props.startTime || "",
  };

  /**
   * Fetch the zones that can be selected
   * @Note could conditionally fetch data but kind of minor request anyway
   */
  componentDidMount() {
    const { headcountId, customerId } = this.props;

    // load up the selector if we haven't join a pre-selected zone.
    // another way to do this coult just be to check this zone on the get-go....
    if (!headcountId) {
      api
        .getZones(customerId)
        .then((zones) => {
          this.setState({ zones });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      api
        .getHeadcounts(customerId)
        .then((headcounts) => {
          const headcount = headcounts.find(
            (h) => h.headcountId === headcountId,
          );

          if (!headcount) {
            alert("This headcount could not be found. Exiting.");
            this.exit();
            return;
          }

          const date = new Date(headcount.start * 1000);
          const startTime = moment(date).format("h:mma");

          this.setState({
            headcountId,
            zone: { zoneName: headcount.zoneName, zoneId: headcount.zoneId },
            initialCount: headcount.totalCount,
            startTime,
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  exit = () => {
    window.location = `${window.location.pathname.split("/count")[0]}`;
  };

  /**
   * Give user ability to manually type in the initial count value
   */
  inputInitialCount = (evt) => {
    this.setState({
      initialCount: parseInt(evt.target.value),
    });
  };

  /**
   * Set the selected zones state
   */
  setZone = (selectedZone) => {
    if (!selectedZone) {
      this.setState({
        zone: undefined,
      });
      return;
    }

    this.setState({
      zone: { zoneId: selectedZone.value },
    });
  };

  /**
   * "Start" a headcounting session and lock the input / zone selector
   */
  setInitialCount = () => {
    const { initialCount, zone } = this.state;
    const count = parseInt(initialCount);

    if (!zone) {
      alert("Please select a zone.");
      return;
    }
    if (!count) {
      alert("Please set an initial value.");
      return;
    }

    const startTime = moment(new Date()).format("h:mma");

    return api
      .initiateHeadcount(count, zone.zoneId)
      .then((headcountId) => {
        this.setState({ headcountId, startTime });
      })
      .catch((err) => {
        alert(`Error initiating headcount:  ${err}`);
      });
  };

  /**
   * Count people.
   *
   * This can be used for setting the initial count, or for adding/subtracting people
   * coming in / out of a pre-existing headcount.
   *
   * @param {Num} delta - +/- x people walking in / out of a pre-existing function
   */
  count = (delta = 1) => {
    const { headcountId, initialCount, countDelta } = this.state;

    // update initialCount if we haven't set one yet
    if (!headcountId) {
      this.setState({ initialCount: initialCount + delta });
    } else {
      this.setState({ countDelta: countDelta + delta }, () => {
        api.sendHeadcountDelta(headcountId, delta).catch((err) => {
          alert(
            "Error sending delta - may want to end the head counting session.",
          );
        });
      });
    }
  };

  /**
   * End the counting session.
   */
  endSession = () => {
    const { headcountId } = this.state;

    api
      .endHeadcountSession(headcountId)
      .then(() => {
        alert("Headcount session successfully ended.");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        alert(`ERROR: headcount session unseccessfully ended: ${err}`);
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <Header title={this.props.customerName || "Headcount"} />
          <Box
            height={300}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <div class="occu-loader"></div>
          </Box>
        </>
      );
    }

    return (
      <Headcount
        {...this.state}
        startTime={this.state.startTime}
        isInitiated={this.state.headcountId}
        startSession={this.setInitialCount}
        count={this.count}
        setZone={this.setZone}
        inputInitialCount={this.inputInitialCount}
        endSession={this.endSession}
        customerId={this.props.customerId}
        customerName={this.props.customerName}
      />
    );
  }
}

// if we're joining a count, we'll have all of these PropTypes
HeadcountContainer.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
  headcountId: PropTypes.number,
  zone: PropTypes.shape({
    zoneId: PropTypes.number,
    zoneName: PropTypes.string,
  }),
  initialCount: PropTypes.number,
  startTime: PropTypes.string,
};
