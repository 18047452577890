/**
 * Constants to be used throughout the application
 */

export const completedHeadcountLimit = 100;

export const CLASSES = {
  STACK: "d-flex flex-column",
  BETWEEN: "d-flex justify-content-between",
  XY_CENTER: "d-flex justify-content-center align-items-center",
};

export const STYLES = {};

export const COLORS = {
  purpleLightest: "#F3F3FD",
  // purpleLight: '#C9D2FA',
  purpleLight: "#e2e1fb",
  purple: "#4F46E5",
  purpleDark: "#34349D",
  greyOne: "#F2F5F8",
  greyTwo: "#E3E8EF",
  greyThree: "#CDD5E0",
  greyFour: "#96A3B6",
  greyFive: "#677489",
  greySix: "#495567",
  greySeven: "#354153",
  greyEight: "#202939",
  greyNine: "#101729",
  redLight: "#F0A9A7",
  red: "#DD524C",
  redDark: "#AA2E26",
};
