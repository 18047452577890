import React from "react";
import { auth } from "../../util";
import Header from "../../components/Header";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const LoginLink = () => {
  return (
    <Box>
      <Header title="Login" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={styles.loginModal}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Card>
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <Typography>
                Please login to use the Occustallation app
              </Typography>
              <Button
                variant="contained"
                color="primary"
                style={{ width: 150 }}
                onClick={() => {
                  window.location.href = auth.LOGIN_URL;
                }}
              >
                Login
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  loginModal: {
    backgroundColor: "rgba(0, 0, 0, .1)",
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: "0",
  },
};

export default LoginLink;
