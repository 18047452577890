import { createMuiTheme } from "@material-ui/core/styles";
import { constants } from "./util";

const {
  purple,
  purpleDark,
  purpleLight,
  greyThree,
  greyFive,
  greySeven,
  greySix,
  red,
  redDark,
  redLight,
} = constants.COLORS;

const cardBoxShadow =
  "0 -20px 20px 0 rgb(255 255 255 / 30%), 0 20px 20px -8px rgb(0 0 0 / 10%)";
const cardBorderColor = "rgba(25, 60, 77, 0.05)";

const SPACING = 4;

const theme = createMuiTheme({
  spacing: SPACING,
  palette: {
    primary: {
      light: purpleLight,
      main: purple,
      dark: purpleDark,
      contrastText: "#fff",
    },
    secondary: {
      light: redLight,
      main: red,
      dark: redDark,
    },
    grey: {
      300: greyThree,
      500: greyFive,
      600: greySix,
      700: greySeven,
    },
    info: {
      light: greyThree,
      main: greySix,
      dark: greySeven,
    },
  },
  // override card styles
  overrides: {
    MuiCard: {
      root: {
        boxShadow: cardBoxShadow,
        border: `1px solid ${cardBorderColor}`,
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: "none",
        fontSize: "1rem",
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          borderRadius: 8,
        },
        "& .MuiInputBase-root": {
          fontSize: "1.25rem",
        },
        "& .MuiInputBase-input": {
          backgroundColor: "white",
          borderRadius: 8,
        },
      },
    },
    MuiDrawer: {
      paperAnchorBottom: {
        borderTopRightRadius: SPACING * 3,
        borderTopLeftRadius: SPACING * 3,
        paddingRight: SPACING * 6,
        paddingLeft: SPACING * 6,
        "& > div": {
          marginRight: -SPACING * 6,
          marginLeft: -SPACING * 6,
          paddingRight: SPACING * 6,
          paddingLeft: SPACING * 6,
        },
      },
    },
    MuiContainer: {
      root: {
        paddingRight: SPACING * 6,
        paddingLeft: SPACING * 6,
      },
    },
  },
});

export default theme;
