import React from "react";

const Logo = (props) => {
  return (
    <svg
      width={146}
      height={77}
      viewBox="0 0 146 77"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={50}
        y={0}
        width={45}
        height={45}
      >
        <path
          d="M94.7 22.788c0 12.1-9.808 21.908-21.907 21.908-12.1 0-21.908-9.808-21.908-21.908C50.886 10.69 60.694.881 72.793.881c12.1 0 21.908 9.808 21.908 21.907z"
          fill="#FFF"
          stroke="#FFF"
          strokeWidth={0.169}
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M94.333 8.796H66.99s-7.917 0-7.917 8.138c0 8.137 7.917 8.137 7.917 8.137h12.37s3.96 0 3.96 4.309c0 3.83-3.96 3.83-3.96 3.83H50.887l2.88 3.829H79.36s7.917 0 7.917-7.66c0-8.137-7.917-8.137-7.917-8.137H66.99s-3.96 0-3.96-4.308c0-4.309 3.96-4.309 3.96-4.309h25.59l1.753-3.83z"
          fill="#FFF"
        />
      </g>
      <path
        d="M94.7 22.788c0 12.1-9.808 21.908-21.907 21.908-12.1 0-21.908-9.808-21.908-21.908C50.886 10.69 60.694.881 72.793.881c12.1 0 21.908 9.808 21.908 21.907z"
        stroke="#FFF"
        strokeWidth={1.412}
      />
    </svg>
  );
};

export default Logo;
