import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { constants } from "../../util";

const LabelAndValue = ({ label, value }) => {
  return (
    <Box display="flex" justifyContent="space-between" style={{ gap: 24 }}>
      <Typography variant="body2" style={{ color: constants.COLORS.greyFive }}>
        {label}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

export default LabelAndValue;
