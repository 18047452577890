import React from "react";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { constants } from "../../util";
import LabelAndValue from "../LabelAndValue";

export default function DeleteHeadcount({
  onClose,
  onDelete,
  isOpen,
  isLoading,
  headcountToDelete,
  errorMessage,
}) {
  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={1}
        style={{ borderColor: constants.COLORS.greyThree }}
      >
        <Typography variant="h6">Delete Headcount</Typography>
        <Box
          style={{
            display: "flex",
            gap: 1,
            marginRight: -12,
          }}
        >
          <IconButton
            aria-label={`Close Delete Drawer`}
            onClick={onClose}
            disabled={isLoading}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Box
        py={4}
        display="flex"
        flexDirection="column"
        flex={1}
        style={{ gap: 16 }}
      >
        <Typography variant="body2">
          Are you sure you want to delete this headcount?
        </Typography>
        {headcountToDelete && (
          <>
            <LabelAndValue
              label="Zone Name"
              value={headcountToDelete.zoneName}
            />
            <LabelAndValue
              label="Headcount id"
              value={headcountToDelete.headcountId}
            />
            <LabelAndValue
              label="Start"
              value={
                headcountToDelete.start &&
                moment(headcountToDelete.start * 1000).format(
                  "MM/DD/YYYY, hh:mm a",
                )
              }
            />
            <LabelAndValue
              label="End"
              value={
                headcountToDelete.end &&
                moment(headcountToDelete.end * 1000).format(
                  "MM/DD/YYYY, hh:mm a",
                )
              }
            />
          </>
        )}
        {errorMessage && (
          <Typography variant="body2" style={{ color: constants.COLORS.red }}>
            {errorMessage}
          </Typography>
        )}
        <Box
          pt={6}
          pb={2}
          px={6}
          borderTop={1}
          style={{
            borderColor: constants.COLORS.greyThree,
            marginRight: -24,
            marginLeft: -24,
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onDelete}
            disabled={isLoading || !headcountToDelete}
          >
            Delete Headcount
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
