import React from "react";
import PropTypes from "prop-types";

/**
 * Standard occuplat buttons.
 * They have the same structure, but stylings based on whether they are submit or cancel styles
 */
const Button = ({ style, onClick, label }) => (
  <button style={style} className="text-center" onClick={onClick}>
    {label}
  </button>
);

/**
 * Individually exported buttons: Buttons that pass on
 */
export const Cancel = ({ onClick, label }) => (
  <Button
    label={label || "Cancel"}
    onClick={onClick}
    style={styles.cancelButton}
  />
);

export const Submit = ({ onClick, label }) => (
  <Button
    label={label || "Submit"}
    onClick={onClick}
    style={styles.supplyButton}
  />
);

/**
 * Alias to Submit button
 */
export const Apply = ({ onClick, label }) => (
  <Submit onClick={onClick} label={label || "Apply"} />
);

const APPLY_BTN_TEXT = "white";
const CANCEL_BTN_TEXT = "rgba(0, 0, 0, .7)";
const CANCEL_BTN_BG = "rgba(0, 0, 0, .02)";
const CANCEL_BTN_BORDER = "solid .5px rgba(0, 0, 0, .7)";
const SUBMIT_BTN_BG = "#0A29CA";

const styles = {
  cancelButton: {
    color: CANCEL_BTN_TEXT,
    borderRadius: ".25rem",
    border: CANCEL_BTN_BORDER,
    backgroundColor: CANCEL_BTN_BG,
    minWidth: "65px",
  },

  supplyButton: {
    color: APPLY_BTN_TEXT,
    backgroundColor: SUBMIT_BTN_BG,
    borderRadius: ".25rem",
    border: "transparent",
    minWidth: "65px",
  },
};

/**
 * Since all styles Inherit from Button, I'll only use proptypes on the OG button
 */
Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};
