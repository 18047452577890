/**
 * Wrapper around fetch to consistently return data / fail successfully
 */
import { constants } from "../util";
import { config, auth } from ".";
console.log("Hitting API @ ", config.API_URL);

/**
 * Helper functions to have dynamic post params, since all post requests assume
 * data being posted will be in JSON
 */
const postParams = (body) => ({
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify(body),
});

/**
 * Currently we're only requesting from the generic API so we don't need
 * to generalize the url
 * @TODO have a better method for creating parameterized requests.
 * Currently we need to define the requests we want in the request
 *
 * @output
 * Success: Resolves json response from the API
 * Failure: Alerts the user, then throws an error on top of notifying the user.
 */
export function request(method, endpoint, params = {}) {
  const token = auth.getToken();
  if (!token) {
    // throw new Error('No token, cant make any requests.');
    return Promise.reject("No Token");
  }

  const url = `${config.API_URL}/${endpoint}`;

  // Add the bearer token auth to the header
  let headers = {};
  if ("headers" in params) {
    headers["Content-Type"] = "application/json";
  }
  headers.Authorization = `Bearer ${token}`;

  return fetch(url, {
    ...params,
    method,
    headers,
  })
    .then((resp) => {
      if (resp.status !== 200) {
        throw new Error(`Error hitting url "${url}"`);
      }
      return resp.json();
    })
    .catch((err) => {
      throw new Error(`Error making request to ${endpoint}: ${err}`);
    });
}

/**
 * Quick checker to make sure the user is logged in or not to avoid making bad requests.
 */
export const isLoggedIn = () => {
  return request("GET", "install/is-logged-in")
    .then(({ isLoggedIn }) => isLoggedIn)
    .catch((err) => {
      console.log("ERROR:isLoggedIn: ", err);
      return false;
    });
};

/**
 * Function to get zones; whether its all zones or filtered by building
 * This will hit the {customerId/buildingId}/zones endpoint, just grabbing
 * the zones. NOT their statuses.
 * @return {ZoneObjs[]}
 */
export function getZones(customerId, buildingFilterId = 0) {
  const endpoint = buildingFilterId
    ? `install/building/${buildingFilterId}/zones`
    : `install/customer/${customerId}/zones`;

  return request("GET", endpoint).then(({ zones }) => zones);
}

/**
 * Helper function to interpret the response from zone/status.
 * Effectively collapses the results into a more interpretable output for the
 * frontend.
 * @param {ZoneObj[]} zones - list of zones returned from /.../zones endpoints
 */
export function getZoneStatuses(zoneIds) {
  return request("POST", "install/zone/status", {
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ zones: zoneIds }),
  }).then(({ zones }) => {
    const COLORS = {
      UP: "#87ED18",
      CAUTION: "yellow",
      DOWN: "red",
      UNINSTALLED: "grey",
    };

    const getSubzoneStatus = (subzone) => {
      let color;
      const secondsLastSeen = (new Date().getTime() - subzone.lastSeen) / 1000;

      if (!subzone.lastSeen) color = COLORS.UNINSTALLED;
      else if (secondsLastSeen < 30) color = COLORS.UP;
      else if (secondsLastSeen < 60 * 15) color = COLORS.CAUTION;
      else color = COLORS.DOWN;

      return {
        color,
        ...subzone,
      };
    };

    return zones.map((z) => {
      let subzones = z.subzones.map(getSubzoneStatus);
      let functioningSubzones = subzones.filter(
        (s) => s.color === COLORS.UP,
      ).length;
      let uninstalledSubzones = subzones.filter(
        (s) => s.color === COLORS.UNINSTALLED,
      ).length;

      let color;
      if (uninstalledSubzones === subzones.length) color = COLORS.UNINSTALLED;
      else if (!functioningSubzones) color = COLORS.DOWN;
      else if (functioningSubzones === subzones.length) color = COLORS.UP;
      else color = COLORS.CAUTION;

      return {
        ...z,
        color,
        subzones,
      };
    });
  });
}

/**
 * Return the list of building objects from the /buildings endpoint
 */
export function getBuildings(customerId) {
  return request("GET", `install/customer/${customerId}/buildings`).then(
    ({ buildings }) => buildings,
  );
}

/**
 * Return a singular zone object from the zone/status endpoint.
 * We use that one because it's more descriptive.
 * While we're at it, we might as well grab adjacent zones and add it
 * to the zone Object
 * @param {*} customerId
 * @param {*} zoneId
 */
export function getZone(customerId, zoneId) {
  let zone;

  return getZones(customerId)
    .then((zones) => {
      zone = zones.filter((z) => z.zoneId === zoneId)[0];
      return request("GET", `install/zone/${zoneId}/adjacent`);
    })
    .then(({ zones }) => {
      zone.adjacentZones = zones;
      // return zone;
      return getZoneStatuses([{ zoneId: zone.zoneId }]);
    })
    .then((statuses) => {
      zone.subzones = statuses[0].subzones;
      return zone;
    });
}

/**
 * Function to return a singular subzone object from the zone/status endpoint.
 * @param {*} customerId
 * @param {*} subzoneId
 */
export function getSubzone(customerId, subzoneId) {
  return getZones(customerId)
    .then((zones) => getZoneStatuses(zones.map((z) => ({ zoneId: z.zoneId }))))
    .then((zoneStatuses) => {
      let subzone;
      zoneStatuses.forEach((z) => {
        if (!subzone) {
          z.subzones.forEach((sz) => {
            if (sz.subzoneId === subzoneId) {
              subzone = sz;
            }
          });
        }
      });

      if (!subzone) {
        throw new Error(
          `Subzone ${subzoneId} not found for customer ${customerId}`,
        );
      }
      console.log("LOOKIE! ", subzone);

      return subzone;
    });
}

/**
 * Add a building into the DB for a given customer.
 * @param {*} customerId
 * @param {*} buildingName
 * @return {Promise} Success/fail indicator
 */
export function addBuilding(customerId, buildingName) {
  return request(
    "POST",
    `install/customer/${customerId}/buildings`,
    postParams({ buildingName }),
  );
}

/**
 * Add a zone into the table
 */
export function addZone(buildingId, zoneName, adjacent, capacity = null) {
  return request(
    "POST",
    `install/building/${buildingId}/zone`,
    postParams({
      zoneName,
      adjacent,
      capacity,
    }),
  );
}

/**
 * Add a subzone into the table
 * @return {Num} the installed hub ID
 */
export function addSubzone(zoneId, name, notes = "") {
  return request(
    "POST",
    `install/zone/${zoneId}/subzone`,
    postParams({ name, notes }),
  ).then(({ subzoneId }) => subzoneId);
}

/**
 * Install / Add a hub into a subzone in the table
 */
export function installHub(hubId, subzoneId) {
  return request("POST", `subzone/${subzoneId}/install`, postParams({ hubId }));
}

/**
 * Return a list of hubs
 * @return {Obj[]}
 * { hubId, hubName }
 */
export function getAvailableHubs() {
  return request("GET", "install/hubs").then(({ hubs }) => hubs);
}

/**
 * Get the adjacent zones to one zone
 */
export function getAdjacentZones(zoneId) {
  return request("GET", `install/zone/${zoneId}/adjacent`).then(
    ({ zones }) => zones,
  );
}

export function updateZone(zoneId, zoneName, capacity, adjacent) {
  console.log({
    zoneName,
    capacity,
    adjacent,
  });
  return request(
    "PATCH",
    `zone/${zoneId}`,
    postParams({
      zoneName,
      capacity,
      adjacent,
    }),
  );
}

export function updateSubzone(id, name, notes, hubId) {
  return request(
    "PATCH",
    `install/subzone/${id}`,
    postParams({ name, notes }),
  ).then(({ subzoneId }) => installHub(hubId, subzoneId));
}

export function getCompletedHeadcounts(customerId) {
  return request(
    "GET",
    `headcount/customer/${customerId}/headcounts/completed?limit=${constants.completedHeadcountLimit}`,
  ).then(({ completedHeadcounts }) => completedHeadcounts);
}

export function getHeadcounts(customerId) {
  return request("GET", `headcount/customer/${customerId}/headcounts`).then(
    ({ headcounts }) => headcounts,
  );
}

export function deleteHeadcount(headcountId) {
  return request("DELETE", `headcount/headcount/${headcountId}`);
}

export function updateHeadcountNote({ headcountId, note }) {
  return request(
    "PATCH",
    `headcount/headcount/${headcountId}/notes`,
    postParams({ notes: note }),
  );
}

/**
 * Make the initial post to start a headcount that people can join onto.
 * @param {*} initialCount
 * @param {*} zoneId
 */
export function initiateHeadcount(initialCount, zoneId) {
  return request(
    "POST",
    "headcount/headcounts",
    postParams({
      initialCount,
      zoneId,
    }),
  ).then(({ headcountId }) => headcountId);
}

/**
 * Update an existing headcount with a +/- delta.
 * Doesn't return data, if it's successful than the return 200.
 */
export function sendHeadcountDelta(headcountId, delta) {
  return request(
    "POST",
    `headcount/headcount/${headcountId}`,
    postParams({ delta, time: new Date().getTime() }),
  );
}

export function endHeadcountSession(headcountId) {
  // /headcount/:id/end
  return request("PATCH", `headcount/headcount/${headcountId}/end`);
}

/**
 * This has been a particularily difficult aspect of this application:
 * Given a building, what zones are in the building? And if i select a
 * zone, what building is it associated to?
 *
 * There are more efficient ways to do this. I may already have the data
 * loaded to acheive this. But at this point I think explicitely naming
 * these would make my life much easier.
 *
 * Each function will just return a list of ID's, and any further data
 * needs can be requested for.
 */
export function filterByBuilding(customerId, buildingId = null) {
  return getZones(customerId, buildingId).then((zones) =>
    zones.map((z) => ({ zoneId: z.zoneId, zoneName: z.zoneName })),
  );
}
export function filterByZone(customerId, zoneId = null) {
  return getZone(customerId, zoneId).then(({ buildingId, buildingName }) => {
    console.log(buildingId, buildingName);
    return { buildingId, buildingName };
  });
}

export function geofence(buildingId, notes = "", start, end) {
  return request(
    "POST",
    "geofence",
    postParams({
      buildingId,
      notes,
      start,
      end,
    }),
  );
}
