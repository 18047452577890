/**
 * Convert the different types of into a zoneSelector object
 */
export const buildingToSelector = ({ buildingId, buildingName }) => ({ label: buildingName, value: buildingId });
export const zoneToSelector = ({ zoneId, zoneName }) => ({ label: zoneName, value: zoneId });
export const subzoneToSelector = ({ subzoneId, subzoneName }) => ({ label: subzoneName, value: subzoneId });

export const toSelector = {
  building: buildingToSelector,
  zone: zoneToSelector,
  subzone: subzoneToSelector,
  hub: (({ hubId, hubName }) => ({ label: hubName, value: hubId }))
};

export const backToForm = {
  zone: ({ label, value }) => ({ zoneId: value, zoneName: label }),
  building: ({ label, value }) => ({ buildingId: value, buildingName: label}),
  subzone: ({ label, value }) => ({ subzoneId: value, subzoneName: label })
};