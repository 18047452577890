import React from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export default function Toast({ onClick, zoneName, time }) {
  return (
    <Card onClick={onClick}>
      <Box
        px={4}
        py={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        minWidth={0}
      >
        <Box minWidth={0}>
          <Typography
            variant="h6"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {zoneName}
          </Typography>
          {time && (
            <Typography variant="body2" style={{ flexShrink: 0 }}>
              {moment(time * 1000).format("MMMM Do YYYY, h:mm a")}
            </Typography>
          )}
        </Box>
        <IconButton>
          <ChevronRightIcon fontSize="large" />
        </IconButton>
      </Box>
    </Card>
  );
}
