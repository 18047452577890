import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Logo from "../../components/Logo";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#00061A",
  },
  toolBar: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
  },
  backToLobbyButton: {
    color: "white",
    textTransform: "none",
  },
}));

const OccuAppBar = ({ history, title, action }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Box height={50}>
          <Logo
            style={{
              marginLeft: -30,
              marginTop: 9,
              height: 52,
              width: 97,
            }}
          />
        </Box>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Box style={{ justifySelf: "end" }}>{action}</Box>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(OccuAppBar);
